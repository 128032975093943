html {
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('./Components/Images/logo_512.png'); */
  background-color: #f3f3f3;
}

.padding {
  padding: .5rem;
}

.grid-view {
  display: grid;
  grid-template-columns: 75% 25%;
}

.row-80 {
  /* background-color: #ccc; */
  height: 80%;
}

.row-20 {
  /* background-color: #ddd; */
  height: 20%;
  display: grid;
  grid-template-rows: 80% 20%;
}

.form-row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.form-Secondrow {
  display: flex;
  margin: 0 0px 17px -16px;

}
.form-row label {
  margin-right: 1rem;
  flex-basis: 25%;
}

.gridview-row-even {
  background-color: #f2f2f2;
}

.gridview-row-odd {
  background-color: #ffffff;
}

.form-row input {
  flex-basis: 75%;
}


.full-width {
  width: 100%;
}

.export-button {
  margin-top: .5rem;
  display: flex;
  justify-content: flex-end;
}

.export-button-alt {
  margin-top: .5rem;
  display: flex;
  justify-content: space-between;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-card-wrapper {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card {
  min-height: 30vh;
  width: 80vw;
}

span {
  color: red;
}

.margin-top {
  margin-top: .5rem;
}

.grid {
  width: 100%;
  height: 80vh;
  margin-top: .5rem;
}

.content-info-bold {
  font-weight: bold;
  text-align: right;
}

.page-header {
  margin: 0;
}

.page-header-wrapper {
  display: flex;
  align-items: center;
}

.contentInfo{
  height: 78vh;
  overflow: hidden;
}
@media screen and (min-width: 900px) {
  .login-card {
    width: 50vw;
  }
}
.modal-90w {
  width: 90%;
  max-width: none!important;
}
.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline;
  float: right;
}
.commntsText{
  position: relative;
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.accountDetaislsBody{
  height: 79vh !important;
  overflow-y: scroll!important;
}